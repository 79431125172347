@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
	font-family: "Campton";
	src: url("./assets/fonts/Campton-BoldDEMO.otf");
	src:
		url("./assets/fonts/Campton-BoldDEMO.otf") format("opentype");
	font-style: normal;
	font-weight: 700
}
@font-face {
	font-family: "Campton";
	src: url("./assets/fonts/Campton-LightDEMO.otf");
	src:
		url("./assets/fonts/Campton-LightDEMO.otf") format("opentype");
	font-style: normal;
	font-weight: 300
} */

@font-face {
	font-family: "Flama";
	src: url("./assets/fonts/Flama-Book.woff");
	font-style: normal;
}
@font-face {
	font-family: "FlamaUltracondensed";
	src: url("./assets/fonts/FlamaUltracondensed-Medium.woff");
	font-style: normal;
}

.font-flama-condensed {
	font-family: 'FlamaUltracondensed';
}


* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Flama';
  color: #111827;
}
